@charset "utf-8";

//ベースカラーの設定

$main-color:#339999;
$main-hover:lighten($main-color,5%);
$main-pale:#e2f5f5;
$main-desaturate:#65a7bc;
$main-border:#cce5e5;

$mv-bg:#d0efef;

$accent-color:#d34c92;
$job-color:#dd73ab;
$form-color:#e9c129;

$txt-color:#333;
$txt-hover:lighten($txt-color,20%);
$txt-gray:#666666;
$em-color:#ff0000;

//仮
$caution-color:#ff0000;
$bg-color:#f5f5f5;
$solid-color:#333;

$link-color:#336699;
$link-hover:lighten($link-color,10%);

$footer-bg:$main-pale;
$copyright-color:#66cccc;

$contact-bg:#fef2eb;
$submit-color:#ff6666;

$label-bg:#ebebeb;

//グリッドの設定
//$max:1400px;
$contents:960px;
$inside:960px;
$outside:1400px;

//線
$menu-border:#ddd;
$table-border:$menu-border;

//各種 色の設定

//基本の間隔
//$interval  --> _layout.scssに設定
$gutter:16px;

//sass各種設定
/*768以上はpc表示*/
$breakpoint-pc: 768px;
/*767以下はスマホ表示・bootstrapはsm*/
$breakpoint-mobile: 767px;
$breakpoint-iphone5: 320px;

$speed:0.3s;
$ease-scale:cubic-bezier(0, 0, 0.1, 1.0);

