@charset "UTF-8";

.u-vw65{    @include max-screen($breakpoint-pc) {
        width: 65vw;
    }}

.u-vw80{    @include max-screen($breakpoint-pc) {
        width: 80vw;
    }}

.u-space{
    width: 1em;
    display: inline-block;
}

.u-break{
    display: inline-block;
}

.u-lh15{line-height: 1.5;}
.u-lh2{line-height: 2;}

.u-center { display:table;margin-left: auto;margin-right: auto;}
.u-txt-c{text-align: center;}
.u-txt-r{text-align: right;}
.u-txt-l{text-align: left;}

.u-comments {
    position: relative;
    padding-left: 1.3em;
    &:before {
    content: "※";
    display: inline;
    position: absolute;
    left: 0;
}
}

.u-fff{background-color: #fff;}

.u-bn{border:none}

/*文字色を強調*/
.u-txt-em{
color: $em-color;
line-height: 1.5 !important;    
}

.u-red{
    color: #ff0000;
}

/*absolute設定したい時、親に指定するクラス*/
.u-pr{position: relative;}

/*通常の強調*/
.em{font-style: normal;font-weight: 700;}


/*文字を大きく*/
.u-txt-big{font-size:1.5em !important;}
.u-txt-s{@include min-screen($breakpoint-pc) {font-size: 1.4rem;}}

/*行間調整用*/
.u-lh15{line-height: 1.5;}
.u-lh1{line-height: 1;}

/*Form 必須項目用*/
.u-must{color: red;font-weight: 700;}
.u-break{word-wrap:break-word;}

/*角丸10px*/
.u-radius10{
    border-radius: 10px;
}

.u-flex{
    display: flex;
}

.u-fullw{
    width: 100%;
}

.u-imgfull{
    max-width: 100%;
}

//iPhone5用調整クラス
.ip5only{
    display: none;
    @include max-screen($breakpoint-iphone5){
    display: inline;
    }    
}

/*スマホ用調整クラス*/
@include max-screen($breakpoint-pc) {
 
.u-col-height {display: flex;flex-wrap: wrap;}
    
.sp-none{ display: none;}  

//調整用 margin・padding 5px刻みで0から100pxまで    
@for $i from 0 through 20 {
  .u-mt#{$i * 5}-sp {
    margin-top:#{$i * 5}px !important
  }
  .u-mb#{$i * 5}-sp {
    margin-bottom:#{$i * 5}px !important
  }
  .u-ml#{$i * 5}-sp {
    margin-left:#{$i * 5}px !important
  }
  .u-mr#{$i * 5}-sp {
    margin-right:#{$i * 5}px !important
  }
  .u-pt#{$i * 5}-sp {
    padding-top:#{$i * 5}px !important
  }
  .u-pb#{$i * 5}-sp {
    padding-bottom:#{$i * 5}px !important
  }
  .u-pl#{$i * 5}-sp {
    padding-left:#{$i * 5}px !important
  }
  .u-pr#{$i * 5}-sp {
    padding-right:#{$i * 5}px !important
  }  
  } 
    
}//スマホ用調整クラスここまで


/*PC用調整クラス*/
@include min-screen($breakpoint-pc) {
    
.pc-none{display: none; } 
    
//調整用 margin・padding 5px刻みで0から100pxまで      
@for $i from 0 through 20 {
  .u-mt#{$i * 5} {
      margin-top:#{$i * 5}px !important
  }
  
  .u-mb#{$i * 5} {
      margin-bottom:#{$i * 5}px !important
  }
  
  .u-ml#{$i * 5} {
      margin-left:#{$i * 5}px !important
  }
  
  .u-mr#{$i * 5} {
      margin-right:#{$i * 5}px !important
  }
  
  .u-pt#{$i * 5} {
      padding-top:#{$i * 5}px !important
  }
  
  .u-pb#{$i * 5} {
      padding-bottom:#{$i * 5}px !important
  }
  
  .u-pl#{$i * 5} {
      padding-left:#{$i * 5}px !important
  }
  
  .u-pr#{$i * 5} {
      padding-right:#{$i * 5}px !important
  }
}   

}//PC用調整クラスここまで




/*‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥
   アニメーション用のclass
‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥*/

@include min-screen($breakpoint-pc) {
.anime-opa {
    transition: opacity $speed;
    &:hover {
        opacity: 0.8;
    }
}
.anime-opaimg {
backface-visibility:hidden;    
transition: opacity 0.5s $ease-scale; 
& img,
& video{
    backface-visibility:hidden;
    transition: opacity 0.5s $ease-scale, transform 0.5s $ease-scale;
}
&:hover {
    opacity: 0.8 !important;
    & img,
    & video{
        backface-visibility:hidden;
        transform: scale(1.05);            
    }
}
}
.anime-img {
backface-visibility:hidden;    
//transition: opacity 0.5s $ease-scale; 
& img,
& video{
    backface-visibility:hidden;
    transition:transform 0.3s $ease-scale;
}
&:hover {
    //opacity: 0.8 !important;
    & img,
    & video{
        backface-visibility:hidden;
        transform: scale(1.05);            
    }
}
}    
    
.js_opaimg {
    backface-visibility:hidden;
    //opacity: 0.8 !important;
    position: relative;
    & img,
    & video {
        backface-visibility:hidden;
        transform: scale(1.05);
    }  
}
    
.anime-ponpon {
    @include anime-ponpon;
//  transform: translateZ(0);
//  backface-visibility: hidden;
//    &:hover,&:focus,&:active{
//    animation: anime-ponpon 1s ease-in-out 1;    
//    }
}
}

    
//@keyframes anime-ponpon {
//  16.65% {
//    transform: translateY(8px);
//  }
//  33.3% {
//    transform: translateY(-6px);
//  }
//  49.95% {
//    transform: translateY(4px);
//  }
//  66.6% {
//    transform: translateY(-2px);
//  }
//  83.25% {
//    transform: translateY(1px);
//  }
//  100% {
//    transform: translateY(0);
//  }
//}
    
@keyframes anime-ponpon {
  16.65% {
    transform: translateY(4px);
  }
  33.3% {
    transform: translateY(-3px);
  }
  49.95% {
    transform: translateY(1px);
  }
  66.6% {
    transform: translateY(-2px);
  }
  83.25% {
    transform: translateY(1px);
  }
  100% {
    transform: translateY(0);
  }
}    
    
@keyframes anime-ponpon-scale {
  16.65% {
    transform: scale(1.02);
  }
  33.3% {
    transform: scale(0.97);
  }
  49.95% {
    transform: scale(1.01);
  }
  66.6% {
    transform: scale(0.99);
  }
  83.25% {
    transform: scale(1.01);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes anime-hang {
    50% {
        transform: translateY(5px);
    }

    100% {
        transform: translateY(0);
    }
}
    

@keyframes fadeIn20 {
    0% {opacity: 0.2}
    100% {opacity: 1}
}
/*旧 Safari 用のベンダー処理*/
@-webkit-keyframes fadeIn20 {
    0% {opacity: 0.2}
    100% {opacity: 1}
}

@keyframes fadeIn {
    0% {opacity: 0}
    100% {opacity: 1}
}
/*旧 Safari 用のベンダー処理*/
@-webkit-keyframes fadeIn {
    0% {opacity: 0}
    100% {opacity: 1}
}

@keyframes fadeOut {
    0% {opacity: 1}
    100% {opacity: 0}
}
/*旧 Safari 用のベンダー処理*/
@-webkit-keyframes fadeOut {
    0% {opacity: 1}
    100% {opacity: 0}
}


.anime-boundin {
    backface-visibility:hidden;
    -webkit-backface-visibility:hidden;
    transform:  translateZ(0);
    will-change: animation;
    -webkit-animation: boundIn 0.8s ease 1 forwards;
    animation: boundIn 0.8s ease 1 forwards;
    opacity: 1;

    perspective: 1000; 
}

 @keyframes boundIn {
    from,
    20%,
    40%,
    60%,
    80%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1)
    }
    0% {
        opacity: 0;
        -webkit-transform: scale3d(0.8, 0.8, 0.8) rotate(0deg);
        transform: scale3d(0.8, 0.8, 0.8) rotate(0deg)
    }
    20% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1)
    }
    40% {
        -webkit-transform: scale3d(0.95, 0.95, 0.95) rotate(0deg);
        transform: scale3d(0.95, 0.95, 0.95) rotate(0deg)
    }
    60% {
        opacity: 1;
        -webkit-transform: scale3d(1.01, 1.01, 1.01);
        transform: scale3d(1.01, 1.01, 1.01)
    }
    80% {
        -webkit-transform: scale3d(0.99, 0.99, 0.99);
        transform: scale3d(0.99, 0.99, 0.99)
    }
    to {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
}   
@keyframes load5 {
    $loadcolor:$main-color;
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em $loadcolor, 1.8em -1.8em 0 0em rgba($loadcolor,0.2), 2.5em 0em 0 0em rgba($loadcolor,0.2), 1.75em 1.75em 0 0em rgba($loadcolor,0.2), 0em 2.5em 0 0em rgba($loadcolor,0.2), -1.8em 1.8em 0 0em rgba($loadcolor,0.2), -2.6em 0em 0 0em rgba($loadcolor, 0.5), -1.8em -1.8em 0 0em rgba($loadcolor, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba($loadcolor, 0.7), 1.8em -1.8em 0 0em $loadcolor, 2.5em 0em 0 0em rgba($loadcolor,0.2), 1.75em 1.75em 0 0em rgba($loadcolor,0.2), 0em 2.5em 0 0em rgba($loadcolor,0.2), -1.8em 1.8em 0 0em rgba($loadcolor,0.2), -2.6em 0em 0 0em rgba($loadcolor,0.2), -1.8em -1.8em 0 0em rgba($loadcolor, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba($loadcolor, 0.5), 1.8em -1.8em 0 0em rgba($loadcolor, 0.7), 2.5em 0em 0 0em $loadcolor, 1.75em 1.75em 0 0em rgba($loadcolor,0.2), 0em 2.5em 0 0em rgba($loadcolor,0.2), -1.8em 1.8em 0 0em rgba($loadcolor,0.2), -2.6em 0em 0 0em rgba($loadcolor,0.2), -1.8em -1.8em 0 0em rgba($loadcolor,0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba($loadcolor,0.2), 1.8em -1.8em 0 0em rgba($loadcolor, 0.5), 2.5em 0em 0 0em rgba($loadcolor, 0.7), 1.75em 1.75em 0 0em $loadcolor, 0em 2.5em 0 0em rgba($loadcolor,0.2), -1.8em 1.8em 0 0em rgba($loadcolor,0.2), -2.6em 0em 0 0em rgba($loadcolor,0.2), -1.8em -1.8em 0 0em rgba($loadcolor,0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba($loadcolor,0.2), 1.8em -1.8em 0 0em rgba($loadcolor,0.2), 2.5em 0em 0 0em rgba($loadcolor, 0.5), 1.75em 1.75em 0 0em rgba($loadcolor, 0.7), 0em 2.5em 0 0em $loadcolor, -1.8em 1.8em 0 0em rgba($loadcolor,0.2), -2.6em 0em 0 0em rgba($loadcolor,0.2), -1.8em -1.8em 0 0em rgba($loadcolor,0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba($loadcolor,0.2), 1.8em -1.8em 0 0em rgba($loadcolor,0.2), 2.5em 0em 0 0em rgba($loadcolor,0.2), 1.75em 1.75em 0 0em rgba($loadcolor, 0.5), 0em 2.5em 0 0em rgba($loadcolor, 0.7), -1.8em 1.8em 0 0em $loadcolor, -2.6em 0em 0 0em rgba($loadcolor,0.2), -1.8em -1.8em 0 0em rgba($loadcolor,0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba($loadcolor,0.2), 1.8em -1.8em 0 0em rgba($loadcolor,0.2), 2.5em 0em 0 0em rgba($loadcolor,0.2), 1.75em 1.75em 0 0em rgba($loadcolor,0.2), 0em 2.5em 0 0em rgba($loadcolor, 0.5), -1.8em 1.8em 0 0em rgba($loadcolor, 0.7), -2.6em 0em 0 0em $loadcolor, -1.8em -1.8em 0 0em rgba($loadcolor,0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba($loadcolor,0.2), 1.8em -1.8em 0 0em rgba($loadcolor,0.2), 2.5em 0em 0 0em rgba($loadcolor,0.2), 1.75em 1.75em 0 0em rgba($loadcolor,0.2), 0em 2.5em 0 0em rgba($loadcolor,0.2), -1.8em 1.8em 0 0em rgba($loadcolor, 0.5), -2.6em 0em 0 0em rgba($loadcolor, 0.7), -1.8em -1.8em 0 0em $loadcolor;
  }
}     



/*‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥
    js用のclass
‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥‥*/
.js_hight{
    height: 100vh;
    overflow: hidden;
}

.js_hide {
    //visibility: hidden;
    display: none;
}

.js_select {
    //visibility: hidden;
    display: block;
}

.js_fixed {    
//    position: fixed;
//    width: 100%;
//    height: 100%;
    overflow: hidden;
}

.js_fixnav {    
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
    padding-top: 20px;
    padding-bottom: 10px;
       }

.js_print{
    display: none;
}

.js_index-service-txt{
    color:#fff;
            & span{color: #fff;
                &:after{background: #fff;}
            }    
    &:after {
      transform: translateX(0);
    }    
}


