.mv-slider-wrap{
background:$mv-bg;
}
.mv-slider{
    @include min-screen($breakpoint-pc) {    
    max-width: 1100px;
    width: 100%;    
    margin-left: auto;
    margin-right: auto;
}
}
.mv-slider-item{
    @include min-screen($breakpoint-pc) {
        & img{
            transition: opacity $speed;
        }    
    &:hover{
        & img{
            opacity: 0.8;
        }    
    }
}
}
.sec-renewal{
padding-top: 10px;
padding-bottom: 40px; 
    @include min-screen($breakpoint-pc) {
    padding-top: 30px;
    padding-bottom: 60px;    
    }    
}
.h-renewal{
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 15px;
//    &+p{
//        font-size: 1.2rem;        
//    }
    @include min-screen($breakpoint-pc) {
    font-size: 2rem;
    margin-bottom: 20px;
//        &+p{
//          font-size: 1.4rem;        
//        }
    }    
}
.index-link-item{
    margin-bottom:30px;
    & img{
        width: 100%;
    }
    & p{
        font-weight: 400;
        transition: color $speed;        
    }
    & a{
        display: block;
    }
    @include min-screen($breakpoint-pc) {
    margin-bottom: 40px;    
    }
    & img{
        transition: opacity $speed;
    }
    & a{
        &:hover{
            & img{
                opacity: 0.8;
            }
            & .index-link-title{
              color: $main-color;  
            }
            & p{
            color: $txt-hover;    
            }
        }
    }
}
.index-link-title{
    font-size: 1.8rem;
    margin: 10px 0 8px;
    display: flex;
    align-items: center;
//    &:before{
//        padding: 4px 5px 0 0;
//        color: $copyright-color;
//    }
    background: url(../img/common/arrow-r.png) no-repeat top 5px left;
    background-size: 20px;
    padding-left: 25px;
    @include min-screen($breakpoint-pc) {
    font-size: 2rem;
    margin: 16px 0 10px;
    transition: color $speed;
    background-size: 21px;
    padding-left: 27px;        
//    &:before{
//    padding-top: 3px;    
//    padding-right: 8px;
//    }         
    }    
}

.sec-about{
    & .l-col-md7{
        margin-bottom: 20px;
    }
    & .l-col-md5{
        margin-bottom: 40px;
    }     
    @include min-screen($breakpoint-pc) {    
    & .l-col-md7,
    & .l-col-md5{
        margin-bottom:0;
    }
        & .l-row{
            margin-bottom: 50px;
        }    
    }
}
.about-table{
    border-top: 1px solid $table-border;
    margin-bottom: 40px;
    & th,& td{
        display: table-cell;
        border-bottom: 1px solid $table-border;
        padding: 10px;
    }
    & th{
        //background: $main-color;
        font-weight: normal;
        width: 90px;
    }
    @include min-screen($breakpoint-pc) {
margin-bottom: 80px;       
    & th,& td{
        padding: 15px 20px;        
    }
        & th{
            width: 120px;
        }    
    }    
}
.shop-nav{
    list-style-type: none;
    margin-top: 10px;
    margin-bottom: 35px;
    & a{
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.3rem;
        border: 2px solid $main-border;
    }
    & img{
        flex: 0 0 63px;
        max-width: 63px;
        height: auto;
    }
    & span{
        flex: 0 0 auto;
        //flex-basis: calc(100% - 63px);
        //max-width: calc(100% - 63px);
        padding-left: 28px;
        position: relative;
        //line-height: 13px;
        
        &:before{         
        position: absolute;
        top: 50%;
        left: 10px;    
        transform: translateY(-6px);
        font-size: 1.4rem;    
        color: $copyright-color;    
        }
    }
    & li{
        margin-bottom: 5px;
    }
    @include min-screen($breakpoint-pc) {
    margin-top: 20px;    
    margin-bottom: 50px;    
    & a{
    font-size: 1.4rem;
    transition: background $speed;    
        &:hover{
            background: $main-pale;
            & img{
                opacity: 0.8;
            }
        }
    }        
    & img{
        flex: 0 0 84px;
        max-width: 84px;
        height: auto;
        transition: opacity $speed;  
    }
    & li{
        margin-bottom:10px;
    }        
    }    
}
.shop-row{
margin-bottom: 20px;
    @include min-screen($breakpoint-pc) {    
margin-bottom: 50px;    
}
}
.shop-nav-name{
    background: url(../img/common/arrow-r.png) no-repeat top 3px left 8px;
    background-size: 14px;
    padding-left:20px;
    @include min-screen($breakpoint-pc) {
    background-position:top 3px left 7px;    
    background-size: 15px;    
    }    
}
.shop-item-data{
    margin-bottom: 30px;
    @include max-screen($breakpoint-pc) {    
        order: 2;    
    }   
    @include min-screen($breakpoint-pc) {
    flex: 0 0 610px;
    max-width: 610px;    
    margin-bottom: 0;        
    }    
}
.shop-item-img{
    margin-bottom: 30px;
    @include min-screen($breakpoint-pc) {
    flex: 0 0 390px;
    max-width: 390px;
    margin-bottom: 0;    
    }
}
.shop-table{
    border-top: 1px dotted #999;
    line-height: 1.8;
    width: 100%;
    & th,
    & td{
        padding:10px 5px;
        font-weight: 400;
        border-bottom: 1px dotted #999;
        position: relative;
    }
    & th{
        width: 70px;
        text-align: left;
    }
    & .gw{
        width: 90px;
    }
    & .map{
        padding-right: 55px;
    }
    @include min-screen($breakpoint-pc) {
    th,
    td{
        padding:12px 16px;
    }        
    & th{
        width: 110px;
    }    
    & .gw{
        width: 110px;
    }
    }
}

.job-feature{
    background-image: url(../img/job/image01_sp.jpg);
    background-color:#f8f9f4;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size:100%;
    min-height: 400px;
    @include max-screen($breakpoint-iphone5) {
    background-size:375px;
    min-height: 1px;    
    }    
    margin: 25px -16px;
    padding: 20px 16px;
    width: calc(100% + 32px);
    & h4{
        font-weight: 400;
        color: $job-color;
        font-size: 2.1rem;
        margin-bottom: 20px;
        @include max-screen($breakpoint-iphone5) {
        font-size: 1.9rem;    
        }        
    }
    & h5{
    background: $job-color;
    color: #fff;
    font-size: 1.5rem;
    padding:5px 10px;
    border-radius: 5px;
    margin-bottom: 5px; 
        @include max-screen($breakpoint-iphone5) {        
        font-size: 1.4rem;    
        }        
    }
    & p{
        margin-bottom: 16px;
        @include max-screen($breakpoint-iphone5) {
        margin-bottom: 10px;    
        }        
    }
    @include min-screen($breakpoint-pc) {
    background-image: url(../img/job/image01_pc.jpg);
    border-radius: 10px;
    margin: 40px 0 60px;
    width:100%;
    padding: 40px;
        & h4{
            font-size: 4.2rem;
        }
        & h5{
            width: 100%;
            height: 175px;
            border-radius: 50%;
            padding: 0 20px;
            display: flex;
            justify-content: center;
            align-items: center; 
            font-size: 2.6rem;
            text-align: center;
            line-height: 1.2;
            margin-bottom: 10px;
        }
        & p{
            font-size: 1.6rem;
            
        }
    }    
}
.job-feature-row{
    width: 180px;
    @include max-screen($breakpoint-iphone5) {
    width: 155px;    
    }
    @include min-screen($breakpoint-pc) {
    width: 622px;    
    }    
}
.datalist{
    position: relative;
    line-height: 2;
    & dt{
        position: absolute;
        left: 0;        
    }
    & dd{
        padding-left: 4em;
    }
}

.privacypolicy-list{
    @include CircleList;
    & li{
        margin-bottom: 5px;
    } 
    margin-top: 10px;
    @include min-screen($breakpoint-pc) {
    margin-top: 15px;    
    }    
}

.sec-privacypolicy{
    padding-bottom: 40px;
    @include min-screen($breakpoint-pc) {
    padding-bottom: 110px;    
    }    
}