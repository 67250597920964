header {
    position: relative;
    padding:13px 16px;
    z-index: 9001;
    width: 100%;    
    //min-height: 76px;
    transition: background $speed;
    background-color: #fff;
//    opacity: 0;
//    visibility: hidden;
    //transition: opacity 1s,visibility 1s;
    @include max-screen($breakpoint-iphone5){
    padding-left: 10px;
    padding-right: 10px;    
    }    
    @include min-screen($breakpoint-pc) {
        //position: fixed;
        position: relative;
        padding: 16px 0 0;
        //height: 165px;
    }
}
//.l-headerはLayoutにて設定

.header-top{
    @include min-screen($breakpoint-pc) {
        display: flex;
        position: relative;
    }
}

.l-logo-header {    
    text-align: center;
    font-size: 1.1rem;
    font-weight: 400;
    width:153px;
    @include max-screen($breakpoint-iphone5) {
    width: 145px;    
    }    
    @include min-screen($breakpoint-pc) {  
    width: 205px;
    //margin-top:5px;    
    }
}

.header-copy{
font-size: 1rem;
color: $txt-gray;
margin-bottom: 5px;
    @include max-screen($breakpoint-iphone5) {
    transform: scale(0.95);
    transform-origin: left;    
    margin-bottom: 3px;    
    }
    @include min-screen($breakpoint-pc) {
    font-size: 1.2rem;
    margin-left: 20px;
    margin-bottom: 0;    
    }    
}

.fix-header{
    @include min-screen($breakpoint-pc) {    
    position: fixed;
    top: -102px;
    box-shadow: 0 2px 4px rgba(#000,0.1);    
    }}
.fix-header--wp{
    @include min-screen($breakpoint-pc) {    
    position: fixed;
    top: -70px;
    box-shadow: 0 2px 4px rgba(#000,0.1);    
    }
}

.btn-header-sp{
    display: block;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    &:nth-of-type(1){
    right: 114px;    
    }
    &:nth-of-type(2){
    right: 58px;    
    }
    @include max-screen($breakpoint-iphone5) {
    width: 42px;
    height:42px;
    margin-top: 3px;    
    &:nth-of-type(1){
    right: 102px;    
    }
    &:nth-of-type(2){
    right: 56px;    
    }        
    } 
    @include min-screen($breakpoint-pc) {
    display: none;    
    }    
}

//::::::::::::::::::
// meunu
//::::::::::::::::::

.menu{
    @include min-screen($breakpoint-pc) {
//align-self: flex-end;
    }    
}

.main-menu {
    list-style-type: none;
    padding: 0;
    font-weight: 700;
    @include max-screen($breakpoint-pc) {
        border-top: solid 1px $menu-border;
    }
    @include min-screen($breakpoint-pc) {        
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
        //margin-left: -20px;
        //margin-right: -20px;
        //font-weight: 700;
    }
}

.main-menu-list {
    position: relative;
    @include max-screen($breakpoint-pc) {
        border-bottom: solid 1px $menu-border;
        font-size: 1.4rem;
        color: $txt-color;
        background: #fff;
        & a {
            display: block;
            padding: 0.9em 30px;
            &:before {
                display: none;
            }
            &[href="#"] {
                pointer-events: none;
            }
        }
        &:before{
            //color: $main-color;
            position: absolute;
            right: 16px;
            top: 14px;
            font-size: 2rem;
        }
        &:after {
            //color: $main-color;
            position: absolute;
            right: 16px;
            top: 14px;
            font-size: 1.4rem;
        }        
    }
    @include max-screen($breakpoint-iphone5) {
        & a{
        padding-left: 16px;
        padding-right: 16px;           
        }
        &:before,
        &:after{
            right: 12px;
        }         
    }    
    @include min-screen($breakpoint-pc) {
        font-size: 1.6rem;
        transition: color $speed;
        flex: 0 0 auto;
        position: relative;
        //max-height: 80px;
        & a {
            //text-align: center;
            //background: $main-pale;
            display: block;
            position: relative;
            padding: 10px 26px;
        }       
        &:before,
        &:after{
            display: none;
        }
        &:after,
        &.icon-blank:after{
            content: "";
            width: 1px;
            height: 20px;
            display: block;
            //background: $txt-color;
            border-right: 1px dotted #999;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            padding: 0;
            margin: 0;
        }
        &:nth-of-type(2){
            & a{
                padding-left: 0;
            }
        }
        &:last-of-type{
            & a{
                padding-right: 0;
            }
            &:after{
                display: none;
            }
        }        
    &:hover {
        color:$main-color;
    }
}
}
.main-menu-list.menu-active {
    color: $main-color;
    @include min-screen($breakpoint-pc) {    
    color: $form-color;
    }}

//:::::::::::::::::::::::::::::::::
// メニューの設定
//:::::::::::::::::::::::::::::::::

//　[≡]
.menu-btn{
    //@extend %eng;
    background: $main-color;
    font-weight: 400;
    display: block;
    text-align: center;
    $size:50px;
    height:$size;
    width:$size;
    position: fixed;
    //position: absolute;
    top: 13px;
    right: 16px;
    vertical-align: bottom;
    z-index: 9999;
    @include max-screen($breakpoint-iphone5){
    right: 10px;
    top: 10px;    
    }    
    & span {
    color:#fff;    
    display: block;
    width:$size - 10px;
    height: 3px;
    position: absolute;
    left:5px;
    transition: all $speed;
        &:nth-of-type(1){top:8px;background:#fff;}
        &:nth-of-type(2){top:18px;background:#fff;}
        &:nth-of-type(3){top:28px;background:#fff;}
        &.menu-txt{bottom:17px;}
        &.menu-txt:before {
    letter-spacing: 0;        
    height: 10px;
    width: 40px;
    margin: 0 -10px;
    content: "メニュー";
    font-size: 1rem;
    z-index: 9995;        
}
    }
}

// [×]
.open {
    //border: 1px solid #fff;
    //position: absolute;
    & span {
        color: #fff;
        &:nth-of-type(1) {top: 18px;transform: rotate(35deg);background: #fff;}
        &:nth-of-type(2) {background: rgba(255, 255, 255, 0);}
        &:nth-of-type(3) {top: 18px;transform: rotate(-35deg);background: #fff;}
        &.menu-txt:before {content: "閉じる";}
        }

}
//スマホ専用メニュー
//右から出て来るメニューの動き --> .l-spmenuに設定
.spmenu {
    @include max-screen($breakpoint-pc) {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;    
    right: 0;
    //display: none;    
    overflow-y: auto;
    overflow-x: hidden;
    overflow-scrolling: touch;
    pointer-events: none;    
    }
}
.spmenu.menuopen {
    @include max-screen($breakpoint-pc) {
    display: block;    
    background-color: rgba(#000, 0.7);        
    z-index: 9996;
    pointer-events:auto;
    overflow-scrolling: touch;    
    }
}

.sp-title{
    background:#fff;
    font-weight: 500;
    font-size: 2.5rem;
    height: 75px;
    padding: 20px;
    display: flex;
    align-items: center;
    color: $main-color;
}

//:::::::::::::::::::::::
// 細かな設定
//:::::::::::::::::::::::
.header-contact{
    @include max-screen($breakpoint-pc) {    
    background: $main-pale;
    padding:20px;
}        
@include max-screen($breakpoint-iphone5) {
padding: 16px 10px;    
}
    @include min-screen($breakpoint-pc) {
    display: flex;
    flex-wrap: wrap;
        
    }    

}
.header-contact-tel{
    @include max-screen($breakpoint-pc) {
        background: #fff;
        border: solid 2px $main-color;
        border-radius: 10px;
        text-align: center;
        padding: 10px;
        margin-bottom: 16px;
    }
    @include min-screen($breakpoint-pc) {
    //flex: 0 0 100%;
    //max-width: 100%;    
    }    
}
.header-contact-cap{
    font-weight: 500;
    font-size: 1.4rem;
    @include min-screen($breakpoint-pc) {  
    font-size: 1.6rem;
    text-align: center;
    //padding-left: 20px;
    //margin-top: 5px;    
    }    
}
.header-contact-note{
    font-size: 1rem;
}
.header-contact-telno{
@include telno($size:2.7rem);
    @include max-screen($breakpoint-iphone5) {
        font-size: 2.5rem;
    }
    @include min-screen($breakpoint-pc) {
    font-size: 3rem;
    margin-top: -3px;
    margin-right: 8px;    
    }    
}
.menu-sns{
    background: $bg-color;
    padding: 20px;
    display: flex;
    justify-content: center;
    & a{
        display: block;
        width: 60px;
        margin:0 16px;
    }
    @include min-screen($breakpoint-pc) {
    background: transparent;
    position: absolute;
    right: -8px;
    top: 90px;
    padding: 0;    
        & a{
            width: 35px;
            margin: 8px;    
        }    
    }    
}
.header-contact-desc{
    flex: 0 0 100%;
    max-width: 100%;
    text-align: right;
    font-size: 1.2rem;
    margin-bottom: 10px;
    padding-right: 18px;
}
.header-contact-wrap{
    @include min-screen($breakpoint-pc) {
    margin: 0 -5px 0 auto;
    padding-left: 400px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
        //&>div,
        &>a{
            margin:0 5px;
        }
    }    
}
.header-job-bnr{
    display: flex;
    color: #fff;
    position: absolute;
    top: -16px;
    right: 0;
    & .lead{
        background: $accent-color;        
        line-height: 1.2;
        padding: 6px 16px;
        font-weight: 500;
        position: relative;
        font-size: 1.6rem;
        @include triangle-set($num: 12px 8px,$color:$accent-color,$dir: right); 
    }
    & .telarea{
        background:$copyright-color;
        font-size: 1.2rem;
        padding: 3px 20px 0;
    }
    & .telno{
        display: block;
        font-size: 2.2rem;
        font-weight: 500;
        line-height: 1;
        margin-top: 1px;
        &:before{
        transform: translateY(1px);
        display: inline-block;
        }
        
    }
}

//::::::::::::::::
// footer
//::::::::::::::::
footer{
    background: $footer-bg;
    display: block;
    position: relative;
    @include min-screen($breakpoint-pc) {
    }   
}
.footer-pickup{
    padding:30px 0 15px;
        & img{
            display: block;
            margin-bottom: 16px;
        }
   
    @include min-screen($breakpoint-pc) {
    padding:60px 0 55px;    
}
}
.footer-title{
    background: $bg-color;
    padding: 30px 0;
    @include min-screen($breakpoint-pc) {
    padding-top: 40px;    
        & .l-contents{
        display: flex;    
        }    
    }
}
.footer-logo{
    width: 235px;
    display: block;
    margin:0 auto 20px;
    @include min-screen($breakpoint-pc) {
    width: 390px;    
    margin:0 0 10px;    
    }    
}
.footer-desc{
    font-size: 1.1rem;
    text-align: center;
    @include min-screen($breakpoint-pc) {
    font-size: 1.4rem;
    text-align: left;    
    }    
}
.footer-sns{
    display: flex;
    flex-wrap: wrap;
    justify-content:center;   
    margin-top: 20px;
    @include min-screen($breakpoint-pc) {
    margin: 0;
    margin-left: 60px;    
    }    
}
.footer-sns-item{
    width: 92px;
    margin:0 8px;
    @include max-screen($breakpoint-iphone5) {
    margin: 0;    
    }    
    & img {
        width: 60px;
        display: block;
        margin: 0 auto 5px;
    }  
    & p{
        font-size: 1rem;
        text-align: center;
        font-weight: 400;
    }
    @include min-screen($breakpoint-pc) {
    width:100px;
    margin: 0 20px;
        &:nth-of-type(3){
            width: 135px;
            margin: 0 10px;
        }        
        & img{
            width: 80px;
        }
        & p{
            font-size: 1.4rem;
        }
    }    
}

//::::::::::::::::
//footer Info
//::::::::::::::::
.footer-info{
padding: 20px 0;  
    @include min-screen($breakpoint-pc) {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 30px 0 0;    
    }    
}
.footer-info-left{
    @include min-screen($breakpoint-pc) {
    flex: 0 0 400px;    
    }    
}
.footer-info-tel{
@include telno($color:#fff,$size:3rem);
margin:5px 0 10px;
justify-content: flex-start;     
    @include min-screen($breakpoint-pc) {
       
    margin-bottom: 10px; 
    }     
    
}
.footer-info-cap{
    font-size: 1rem;
    margin-bottom: 5px;
    @include min-screen($breakpoint-pc) {
    font-size: 1.2rem;
    margin-bottom: 10px;    
    }    
}
.footer-info-name{
    //text-align: center;
    font-size: 2rem;
    margin-bottom: 5px;
    font-weight: 500;
    @include min-screen($breakpoint-pc) {
    //text-align: left;
    margin-bottom: 10px;    
    }   
}
.footer-info-address{
    font-size: 1.3rem;
    line-height:1.8;
    //& a{color: #fff;}
    @include min-screen($breakpoint-pc) {
    font-size: 1.4rem;    
    }    
}
.footer-link{
list-style-type: none;
display: flex;
justify-content: space-between;
margin: 10px 0 15px;    
    & li{
        padding: 10px 0;
        font-size: 1.4rem;
    }
    & a{
        display: block;
        color: $link-color;
        transition: color $speed;
        &:hover{
        color: $link-hover;
        text-decoration: underline;    
        }
    }
    
}
.copyright {
    display: block;
    background: $copyright-color;
    color: #fff;
    font-size: 1rem;
    padding: 10px 16px;
    text-align: center;

    @include min-screen($breakpoint-pc) {
        font-size: 1.2rem;
        padding: 13px;
    }
}

.top-btn {
    position: fixed;
    right:5px;
    bottom:20px;
    width: 45px;
    height:45px;
    /* 変更 */
    transition: opacity $speed,
    visibility $speed,
    background $speed;
    opacity: 0;
    visibility: hidden;
    z-index: 8000;
    border-radius: 50%;
    border: 1px solid $copyright-color;
    overflow: hidden;
    background: url(../img/common/top-btn--on.png) no-repeat $copyright-color;
    background-size: 100%;
    
    & img {
        width:100%;
        transition: opacity $speed;
    }
    @include min-screen($breakpoint-pc) {
    cursor: pointer;
    right:20px;
    bottom:20px;    
    width:60px;
    height:60px;
        &:hover{
            border-color: #fff;
            & img{
                opacity: 0;
            }   
        }
    }
}
.fade-btn{
  //display: block;
  opacity: 1 !important;
  visibility: visible !important; 
}
.btn-fix{
    position: absolute;
    bottom:65px;   
}

