@charset "UTF-8";

.l-max{
    width: 100%;
    padding: 0 16px;
    @include min-screen($breakpoint-pc) {
        //width: $max;
        margin: 0 auto;
        padding: 0;
    }
}

.l-contents{
    width: 100%;
    padding: 0 16px;
    @include min-screen($breakpoint-pc) {
        width: $contents;
        margin: 0 auto;
        padding: 0;
    }
}
.l-outside{
    width: 100%;
    padding: 0 16px;
    @include min-screen($breakpoint-pc) {
        max-width: $outside;
        min-width: $contents;
        margin: 0 auto;
        padding: 0;
    }    
}
.l-inside{
    width: 100%;
    padding: 0 16px;
    @include min-screen($breakpoint-pc) {
        width: $inside;
        margin: 0 auto;
        padding: 0;
    }
}

.l-1200{
    width: 100%;
    padding: 0 16px;
    @include min-screen($breakpoint-pc) {
        width: 1200px;
        margin: 0 auto;
        padding: 0;
    }
}

.l-inside1000{
    width: 100%;
    padding: 0 16px;
    @include min-screen($breakpoint-pc) {
        width: 1000px;
        margin: 0 auto;
        padding: 0;
    }
}

.l-inside800{
    width: 100%;
    padding: 0 16px;
    @include min-screen($breakpoint-pc) {
        width: 800px;
        margin: 0 auto;
        padding: 0;
    }
}

.l-float-l {
  float:left;
}

.l-float-r {
  float:right;
}

 /*グリッド配置のラッパー*/
.l-wrap{
    margin: 0 -16px;
    //width: 100%;
}
 /*クリアフィックス*/
.l-wrap:after{
    content:"";
    display:block;
    float: none;
    clear:both;    
}

/*map*/
.l-map{width: 100%;
    margin: 0 auto;
    position: relative;
    padding-bottom: 80%;
    overflow: hidden;
      @include min-screen($breakpoint-pc) {
          padding-bottom: 55.55%;}}

.l-map iframe,
.l-map object,
.l-map embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
    height: 100%;}

.l-btn-index-service{
    margin-top: 20px;
    @include min-screen($breakpoint-pc) {
        display: none;
    }
}

.l-btn{
    padding-top:30px;
    padding-bottom:25px; 
    & a{
        display: table;
        margin: auto;
    }
    @include min-screen($breakpoint-pc) {
    padding-top:50px;
    padding-bottom: 20px;    
    }    
}
.l-btn-root{
    margin: 30px 0 ;
    & a{
        margin-bottom: 20px;
    }
    @include min-screen($breakpoint-pc) {
    margin: 70px 0 0;
    display: flex; 
    flex-wrap: wrap;    
    }    
}


.l-btn_1of2--submit{}
.l-btn-caution{
margin: 30px 0;
    @include min-screen($breakpoint-pc){
margin: 50px 0 70px;        
    }    
}
.l-footer-tel{
   
}
.l-footer-bnr{
   
}
.l-form-btn{
    margin-top:30px;
    & button{margin-bottom: 16px !important;}
    @include min-screen($breakpoint-pc){
        & button{width: 300px !important;}
        display: flex;
        width: 700px;
        margin: 70px auto 0;
    }
}

.l-btn-benefits{
    justify-content: center;
}

//:::::::::::::::::::::::::
// Common
//:::::::::::::::::::::::::
.l-header {
    position: relative;
    //height: 50px;
    @include min-screen($breakpoint-pc) {
        position: relative;
        //min-width: 1170px;
        width: $contents;
        margin: 0 auto;
    }
}
.l-main-menu {
    @include min-screen($breakpoint-pc) {
        //position: relative;
    }
}

// メニュー回りのレイアウト
.l-spmenu{
    @include max-screen($breakpoint-pc) {
    background:#fff;
    width:calc(100% - 50px);
    position: absolute;
    top:0;
    right:0;
    //右から出て来るメニューの動き  
    transform: translate(100%, 0px);
    transition: transform $speed; 
    visibility: hidden;    
    //height: 0;
    padding-bottom: 40px;    
    }
    @include min-screen($breakpoint-pc) {
    //display: flex;
    }    
}

//右から出て来るメニューの動き 
.menuopen .l-spmenu{   
    transform: translate(0, 0);
    visibility:visible;
    min-height: 100vh;
    //padding-bottom: 40px;
}
.l-footer{
    padding-top: 40px;
    @include min-screen($breakpoint-pc) {
    display: flex;
    flex-wrap: wrap;    
    width: 710px;
    margin:0 auto;    

    }   
}

//:::::::::::::::::::::::::
// Blog
//:::::::::::::::::::::::::

.l-blog-wrap {
    display: flex;
    flex-direction: column;
    @include max-screen($breakpoint-pc) {
        & a.btn-return.blog {
            order: -1;
        }
        &.main{
            padding-bottom: 30px;
        }
    }

    @include min-screen($breakpoint-pc) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}
.l-bloglist-main{
    flex-shrink: 0;
    order:-1; 
    @include min-screen($breakpoint-pc) {    
    width:820px;
    flex: 0 0 820px;    
    }
}
.l-bloglist-side{
    @include max-screen($breakpoint-pc) {

    //order: 5; 
    //margin: 50px 0;
    } 
    @include min-screen($breakpoint-pc) {      
    flex-shrink: 0;
    min-width: 260px;
    max-width: 260px;    
    }    
}
.l-pagenation{
    margin-left: auto;
    margin-right: auto;
}


/*========================
    グリッド（flex）の設定
========================*/

/*flex 均等配置*/
.l-flex{display: flex;}

.l-flex-sb{
    display: flex;
    justify-content: space-between;
}
.l-flex-aic{
    display: flex;
    align-items: center;
}


 /*グリッド配置の「行」*/
//基本は32px空き
.l-row{
    $gutter:16px;
    display: flex;
    flex-wrap: wrap;
    margin-left:-($gutter);
    margin-right:-($gutter);
    & [class^="l-col-"],
    & [class*="l-col-"]{
        padding-left:$gutter;
        padding-right:$gutter;
    }    
}

/*中央揃え（調整用/親要素）*/
.l-align-items-center {
  align-items: center;
}

/*中央揃え（調整用/子要素）*/
.l-align-self-center {
  align-self: center;
}

/*下揃え（調整用/子要素）*/
.l-align-self-end {
  align-self: flex-end;
}


@include max-screen($breakpoint-pc) {
// ガター（間隔）の設定 .l-rowと一緒に指定
    %l-gutter{
    $gutter:0px;    
    display: flex;
    flex-wrap: wrap;
    margin-left:-($gutter);
    margin-right:-($gutter);
    & [class^="l-col-"],
    & [class*="l-col-"]{
        padding-left:$gutter;
        padding-right:$gutter;
    }          
    }    
    
.l-gutter-sm0{
    $gutter:0px;
    display: flex;
    flex-wrap: wrap;
    margin-left:-($gutter);
    margin-right:-($gutter);
    & [class^="l-col-"],
    & [class*="l-col-"]{
        padding-left:$gutter;
        padding-right:$gutter;
    }  
} 
    
.l-gutter-sm5{
    $gutter:2.5px;
    display: flex;
    flex-wrap: wrap;
    margin-left:-($gutter);
    margin-right:-($gutter);
    & [class^="l-col-"],
    & [class*="l-col-"]{
        padding-left:$gutter;
        padding-right:$gutter;
    }  
}     
.l-gutter-sm10{
    $gutter:5px;
    display: flex;
    flex-wrap: wrap;
    margin-left:-($gutter);
    margin-right:-($gutter);
    & [class^="l-col-"],
    & [class*="l-col-"]{
        padding-left:$gutter;
        padding-right:$gutter;
    }  
}
.l-gutter-sm16{
    $gutter:8px;
    display: flex;
    flex-wrap: wrap;
    margin-left:-($gutter);
    margin-right:-($gutter);
    & [class^="l-col-"],
    & [class*="l-col-"]{
        padding-left:$gutter;
        padding-right:$gutter;
    }  
}    
.l-gutter-sm20{
    $gutter:10px;
    display: flex;
    flex-wrap: wrap;
    margin-left:-($gutter);
    margin-right:-($gutter);
    & [class^="l-col-"],
    & [class*="l-col-"]{
        padding-left:$gutter;
        padding-right:$gutter;
    }  
}
    
    .l-col-sm1,
    .l-col-sm2,
    .l-col-sm5of1,
    .l-col-sm3,
    .l-col-sm4,
    .l-col-sm5,
    .l-col-sm6,
    .l-col-sm7,
    .l-col-sm8,
    .l-col-sm9,
    .l-col-sm10,
    .l-col-sm11,
    .l-col-sm12 {
      position: relative;
      width: 100%;
      min-height: 1px;
    }
    .l-col-sm12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .l-col-sm11 {
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }

    .l-col-sm10 {
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }

    .l-col-sm9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .l-col-sm8 {
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }

    .l-col-sm7 {
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }

    .l-col-sm6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .l-col-sm5 {
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .l-col-sm4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .l-col-sm3 {
        flex: 0 0 25%;
        max-width: 25%;
    }
    
    .l-col-sm5of1{
        flex: 0 0 20%;
        max-width: 20%;
    }
    
    .l-col-sm2 {
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }

    .l-col-sm1 {
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }

    }

@include min-screen($breakpoint-pc) {
.l-flex-md{
    display:flex;}
    .l-aligncenter-md{
        align-items: center;
    }
    .l-wrap-md{
        flex-wrap: wrap;
    }
.l-gutter-md0{
    $gutter:0;
    display: flex;
    flex-wrap: wrap;
    margin-left:-($gutter);
    margin-right:-($gutter);
    & [class^="l-col-"],
    & [class*="l-col-"]{
        padding-left:$gutter;
        padding-right:$gutter;
    }  
}
.l-gutter-md5{
    $gutter:2.5px;
    display: flex;
    flex-wrap: wrap;
    margin-left:-($gutter);
    margin-right:-($gutter);
    & [class^="l-col-"],
    & [class*="l-col-"]{
        padding-left:$gutter;
        padding-right:$gutter;
    }  
}    
    
.l-gutter-md10{
    $gutter:5px;
    display: flex;
    flex-wrap: wrap;
    margin-left:-($gutter);
    margin-right:-($gutter);
    & [class^="l-col-"],
    & [class*="l-col-"]{
        padding-left:$gutter;
        padding-right:$gutter;
    }  
}
    
    
.l-gutter-md16{
    $gutter:8px;
    display: flex;
    flex-wrap: wrap;
    margin-left:-($gutter);
    margin-right:-($gutter);
    & [class^="l-col-"],
    & [class*="l-col-"]{
        padding-left:$gutter;
        padding-right:$gutter;
    }  
}
    
.l-gutter-md20{
    $gutter:10px;
    display: flex;
    flex-wrap: wrap;
    margin-left:-($gutter);
    margin-right:-($gutter);
    & [class^="l-col-"],
    & [class*="l-col-"]{
        padding-left:$gutter;
        padding-right:$gutter;
    }  
}

.l-gutter-md40{
    $gutter:20px;
    display: flex;
    flex-wrap: wrap;
    margin-left:-($gutter);
    margin-right:-($gutter);
    & [class^="l-col-"],
    & [class*="l-col-"]{
        padding-left:$gutter;
        padding-right:$gutter;
    }  
}
    
.l-gutter-md50{
    $gutter:25px;
    display: flex;
    flex-wrap: wrap;
    margin-left:-($gutter);
    margin-right:-($gutter);
    & [class^="l-col-"],
    & [class*="l-col-"]{
        padding-left:$gutter;
        padding-right:$gutter;
    }  
}    

    
.l-col-md1,
.l-col-md2,
.l-col-md5of1,
.l-col-md7of1,
.l-col-md7of3,    
.l-col-md3,
.l-col-md4,
.l-col-md5,
.l-col-md6,
.l-col-md7,
.l-col-md8,
.l-col-md9,
.l-col-md10,
.l-col-md11,
.l-col-md12,
.l-col-md-auto    {
      position: relative;
      width: 100%;
      min-height: 1px;
}
    .l-col-md-auto {
        flex: 0 0 auto;
        max-width: 100%;
    }    

    .l-col-md12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .l-col-md11 {
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }

    .l-col-md10 {
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }

    .l-col-md9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .l-col-md8 {
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }

    .l-col-md7 {
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }

    .l-col-md6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .l-col-md5 {
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .l-col-md4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .l-col-md3 {
        flex: 0 0 25%;
        max-width: 25%;
    }
    
    .l-col-md1of5 {
        flex: 0 0 20%;
        max-width: 20%;
    }    

    .l-col-md2 {
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }

    .l-col-md1 {
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }
}


