@charset "UTF-8";
main{    
    @include min-screen($breakpoint-pc) {
    }
}
.index-main{
}
.page-main{
    margin-top: 35px;
    @include min-screen($breakpoint-pc) {
        margin-top: 50px;
    }    
}
//ボタン
.btn-default{
@include btn($bg:$main-color,$hover:#fff);
color: $txt-color;
padding: 0.4em 20px;
}

.btn-return{
    @include btn($bg:#fff,$hover:$main-color,$direction:left);
    color: $main-color;
    border: 1px solid $main-color;
    font-size: 1.4rem;
    margin: 20px 0 50px;
    width: 200px;
    @include max-screen($breakpoint-iphone5) {    

    }
    @include min-screen($breakpoint-pc) {
    margin: 40px 0 100px;
    font-size: 1.8rem;
    min-width: 250px;
        &:hover{
            color: #fff;
            
        }
    }    
}
.btn-map{
    @include btn($bg:#a0c97b,$hover:lighten(#a0c97b,10%));
    display: inline-block;    
    color: #fff;
    font-size: 1rem;
    padding: 3px 0;
    width: 45px;
    border-radius: 20px;
    position: absolute;
    right: 0;
    top: calc(50% - 12px);
    @include min-screen($breakpoint-pc) {
    font-size: 1.4rem;
    min-width: 60px;
    width: 60px;
    display: inline-block;
    height: 28px;    
    padding: 0;
    line-height: 28px;
    top: calc(50% - 14px);    
    }    
    
}
.btn-form {
    @include btn($hover:#fff);
    color: $txt-color;
    font-weight: 700;
    border-width: 3px;
    margin-top: 20px;
    &:before {
        right: 15px;
    }
    &--modelhouse{
    font-size: 2rem;    
    }
    @include max-screen($breakpoint-iphone5) {
        max-width: 100%;
        padding-right: 15px;
        padding-left: 0;
    }
    @include min-screen($breakpoint-pc) {
        margin-top: 30px;
    &--modelhouse{
    font-size: 3.6rem;
    width: 450px;
    min-width: 450px;
    padding: 12px 20px;
    margin-top: 40px;    
    }        
        &:hover {
            &:before {
                right: 15px;
            }
        }
    }
}

.btn-index-link{
    @include min-screen($breakpoint-pc) {
    width: 350px;
    margin-left: 0;    
    }
}

.btn-header-shop,
.btn-header-job{
    @include btn($bg:$main-desaturate,$hover:lighten($accent-color,10%),$direction:left);
    border-radius: 50px;
    line-height: 1.3;
    margin-top: 10px;
    &:before{
        //right: auto;
        left: 20px;
        font-size:30px;
        transform: translateY(-50%);
    }
    @include max-screen($breakpoint-iphone5) {
    font-size: 1.65rem;
    padding: 0.6em 10px; 
    padding-left: 30px;       
        &:before{
            font-size: 25px;
            left: 15px;
        }        
    }
    @include min-screen($breakpoint-pc) {
    margin: 0;
    min-width: 0;
    padding-left: 48px;
    padding-right: 30px;
    text-align: center;    
        &:before{
            font-size: 20px;
        }
    &:hover{
        transform: translateY(0)!important;
        animation:none!important;
                &:before{
                    //right: auto;
                    left: 20px;
        }        
    }        
    }
}
.btn-header-job{
    background: $accent-color;
    padding-left: 10px;
    &:before{
        //transform: translateY(-50%);
    }
    &:hover{
     //background:lighten($sub-color,5%);
    }
    }
.btn-header-form,
.btn-footer-form,{
    @include btn($bg:$form-color, $hover:lighten($form-color,10%), $direction:left);
    border-radius: 5px;
    padding:8px 5px;
    font-size: 1.5rem;
    padding-left: 20px;
    margin-top: 10px;
    &:before{
        left: 10px;
        //font-size:17px;
        transform: translateY(-50%);
    }
    @include max-screen($breakpoint-iphone5) {
    font-size: 1.3rem;
    margin-top: 5px;           
    }
    @include min-screen($breakpoint-pc) {
    margin:0 0 10px;
    font-size: 1.6rem;
    padding:8px;
    padding-left: 25px;
    min-width: 230px;
    width: 230px;
    &:before{
    left:12px;
    font-size: 18px;    
    }    
    }
}
.btn-footer-form{
    @include max-screen($breakpoint-pc) {
    font-size: 1.6rem;
    width: 230px;    
    margin:10px 0 0;    
    }    
}

.btn-onlineshop{
@include btn;
border-radius: 50px;
min-width: 100%;
margin-top: 20px;
padding: 0.8em 16px 0.8em 0;
    &:after{
        position: absolute;
        top:50%;
        right:16px;
        transform: translateY(-50%);
    }
    @include max-screen($breakpoint-iphone5) {
    margin-top: 16px;    
    padding: 0.9em 16px 0.9em 0;
    font-size: 1.5rem;  
    }
    @include min-screen($breakpoint-pc) {
    margin-top: 25px;
    padding: 20px;
    min-width: 450px;
    &:after{
        right:30px;
    }        
    }
}

//ページ上部の見出し
.page-title {
    font-weight: 400;
    color: $txt-gray;
    font-size: 2rem;
    background-color:$mv-bg;
    background-size: cover;
    background-position: center center;
    height: 100px;
    display: flex;
    align-items: center;
    & span{
        margin-left: 16px;
        margin-right: 16px;
    }
    @include min-screen($breakpoint-pc) {
    font-size: 2.8rem;
    height: 120px;    
    background-size:1300px;
        & span{
            display: block;
            width:$contents;
            margin: auto;
        }    
    }
}
$pagelist: about, shop, inq, job, privacypolicy;
@each $name in $pagelist{
.page-title.#{$name} {
    background-image: url(../img/#{$name}/title_sp.jpg);
  }
    @include min-screen($breakpoint-pc) {
.page-title.#{$name} {
    background-image: url(../img/#{$name}/title_pc.jpg);
  }        
    }    
}

//パンくず
.breadcrumb {
    font-size: 1.0rem;
    height: 30px;
    overflow-y: hidden;
    margin: 0;
    //background: #ccc;
    ol {
        list-style: none;
        overflow-x: scroll;
        white-space: nowrap;
        padding: 10px 4px 15px;
        //color: #666;    
    }
    a {
        
        text-decoration: underline;
    }
    li {
        color:#fff;
        display: inline;
        &:before {
            content: "＞";
            margin: 0 .5em;
        }
        &:first-of-type:before {
            display: none;
        }
    }
    @include min-screen($breakpoint-pc) {
        font-size: 1.2rem;
        padding: 20px 0 0;
        //margin: 0 0 90px;
        height: auto;
        ol {
            overflow-x: auto;
            white-space: auto;
            padding: 0;
        }
        a{
            //transition: color $speed;
            &:hover{text-decoration: none;}
        }
    }
}

//見出し
.h-large{
    font-weight: 400;
    font-size: 2rem;
    border-left: solid 4px $form-color;
    padding: 5px 0 5px 10px;
    margin-bottom: 16px;
    @include min-screen($breakpoint-pc) {
    font-size: 3.6rem;
    border-width: 5px;
    padding-left: 20px;
    margin-bottom: 30px;
    //margin-top: 80px;    
    }    
}
.h-middle{
font-size: 1.8rem;
margin-bottom: 8px;    
    @include min-screen($breakpoint-pc) {
font-size: 2.4rem;
margin-bottom: 10px;        
    }    
}
.h-small{
font-size: 1.6rem;
margin-bottom: 10px;    
    @include min-screen($breakpoint-pc) {
font-size: 2rem;
margin-bottom: 10px;     
    }    
}

.h-solid-middle{
    background: $mv-bg;
    padding: 8px;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 1.2;
    border-radius: 5px;
    @include min-screen($breakpoint-pc) {
    font-size: 18px;
    padding: 12px 20px;
    margin-bottom: 20px;
    //border-radius: 10px;    
    }     
}
.h-solid-middle--shop,
.h-solid-middle--privacy{
    @extend .h-solid-middle;
    margin-bottom: 15px;
    @include min-screen($breakpoint-pc) {
    font-size: 2rem;
    margin-bottom: 30px;    
    }    
}
.h-solid-middle--privacy{
    margin-top: 30px;
    @include min-screen($breakpoint-pc) {
    margin-top: 40px;    
    }
}

//table
.table-default{
    border-top: 1px solid $table-border;
    line-height: 1.8;
    width: 100%; 
    
    th,
    td{
        border-bottom: 1px solid $table-border;
        padding:0.6em 10px;
    }
    th{
        font-weight: 500;
        text-align: left;
        background:$mv-bg;
        width: 90px;        
        //vertical-align: top;
    @include max-screen($breakpoint-pc) {        
        &:first-of-type{
        border-top:none;
        }        
        }}
    @include min-screen($breakpoint-pc) {
    table-layout:fixed;   
    th,
    td{
        border-top: 1px solid $table-border;
        display: table-cell;
        padding:16px;
    }
        th{
            min-width: 150px;
            width: 150px;
        }        
        
    }    
}

//テキスト
.desc-txt{
    @include min-screen($breakpoint-pc) {
        text-align: center;
    }
}
.lead-txt{
    @include min-screen($breakpoint-pc) {    
    font-size: 1.6rem;
    }}
.indent-txt{
    text-indent: 1em;
}
.note-txt{
    font-size: 1.2rem;
    @include min-screen($breakpoint-pc) {
    font-size: 1.4rem;    
    }    
}

//ラベル
.label--pickup {
    @include label;
    position: absolute;
//    top: 50%;
//    transform:translateY(-50%);
    left: 0;
    @include min-screen($breakpoint-pc) {
    //display: inline-block;
    //margin-right: 10px;
    }}

//フォーム関連
.form-copy{
    color: red;
    text-align: center;
    //font-size: 2rem;
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 10px;
    @include min-screen($breakpoint-pc) {
    font-size: 2.4rem;
    margin-bottom: 20px;
        &+p{
            font-size: 1.4rem;
        }
    }    
}

//送信完了
.thx-txt{
    margin-top: 20px;
    padding: 0 6px;
    @include min-screen($breakpoint-pc) {
    margin-top: 30px;    
    text-align: center;    
    }    
}
.thx-chara{
    display: block;
    margin:10px auto 0;
    width: 90%;
    @include min-screen($breakpoint-pc) {
    width: auto;
    margin-top: 40px;    
    }    
}
//資料請求
.contact-detail{
    margin-top: 60px;
    margin-bottom:50px;
    @include min-screen($breakpoint-pc) {
    margin-top: 100px;
    margin-bottom: 100px;    
    }    
}

//コンタクト
.contact-area{
    background: $contact-bg;
    border:solid 4px $form-color;
    padding: 20px 16px;
    margin-bottom: 50px;
    border-radius: 16px;
    @include max-screen($breakpoint-iphone5) {
    padding: 16px 10px; 
    } 
    @include min-screen($breakpoint-pc) {
    border-width: 4px;
    border-radius: 20px;    
    text-align: center;
    padding: 30px 50px;
    margin: 30px 0 90px;    
    }    
}
.h-contact-area{
    background: $form-color;
    color: #fff;
    text-align: center;
    border-radius: 40px;
    font-size: 2rem;
    padding: 5px;
    margin-bottom: 10px;
    @include min-screen($breakpoint-pc) {
    font-size: 2.6rem;
    margin-bottom: 20px;
        &+p{
            font-size: 1.6rem;
        }        
    }    
}
.contact-area-telno{
@include telno($size:3.5rem);
margin-top:3px;
    &:before{
        margin-top:5px;
    }
    @include max-screen($breakpoint-iphone5) {
    font-size:3.3rem;    
    }
    @include min-screen($breakpoint-pc) {
    margin-top:0;
    font-size: 5.4rem; 
    }    
}
.contact-area-telcap{
    text-align: center;
    color: $main-color;
    font-size: 1.1rem; 
    @include min-screen($breakpoint-pc) {
    font-size: 1.3rem;
    //margin-top: 5px;
    text-align: left;
    padding-left:68px;    
    }    
}
.btn-contact{
@include btn($bg:#fff,$hover:$main-color,$direction:"left");
color: $main-color;
border: 1px solid $form-color;
min-width: 100%;
padding: 10px 0 10px 30px;
border-radius: 5px;
margin-top: 10px;    
    &:before{
        left: 16px;
    }
    @include max-screen($breakpoint-iphone5) {
    font-size: 1.6rem;
    padding-left: 20px;    
        &:before{
            left: 12px;
        }    
    }
    @include min-screen($breakpoint-pc) {
    min-width: 100%;
    font-size: 2.4rem;
    padding:0.8em 20px 0.8em 40px;
    line-height: 1;    
        &:before{
            left: 20px;
        }
        &:hover{
            color: #fff;
            border-color: $main-color;
        }
    }    
}


