@charset "UTF-8";

//form要素 すべてのスタイルをリセット
.contact-form input[type="text"],
.contact-form input[type="tel"],
.contact-form input[type="address"],
.contact-form input[type="email"],
button.btn-return,
button.btn-submit,
.contact-form button,
.contact-form textarea,
.contact-form select,
.wpcf7-form input[type="text"],
.wpcf7-form input[type="tel"],
.wpcf7-form input[type="address"],
.wpcf7-form input[type="email"],
button.btn-return,
button.btn-submit,
.wpcf7-form button,
.wpcf7-form textarea,
.wpcf7-form select,
{
margin: 0;
padding: 0;
background: none;
border: none;
border-radius: 0;
outline: none;
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
}

//IE11 selectの矢印を非表示
select::-ms-expand {
    display: none;
}

//::::::::::::::::
// ラジオボタン
//::::::::::::::::
//.radio-wrap{
//    position: relative;
//    display: flex;
//    align-items: center;
//}
//label{display: block;
//    display: inline-block;
//    padding: 0.3em 0 0.3em 3px;
//    @include min-screen($breakpoint-pc){
//    padding: 0.1em 25px 0.1em 3px;
//    }
//}
//input[type="radio"]+label:before,
//input[type="radio"]+label:after {
//@include vertical-align; 
//  position: absolute;
//  content:"";
//  border-radius: 50%;
//}
//input[type="radio"]+label:before {
//  left: 0;
//  width:16px;
//  height:16px;
//  border: 1px solid #999;
//  background: #fff;   
//}
//input[type="radio"]+label:after {  
//  width: 10px;
//  height: 10px;
//  background: $main-color;
//  left:3px;
//  opacity: 0;
//}
//input[type="radio"]:checked+label:after {
//  opacity: 1;
//}
//
//input[type="radio"]{
//    margin-right: 5px;
//    visibility: hidden;
//}

span.wpcf7-list-item{
    display: block;
    margin: 0;
}
.category .wpcf7-list-item label{
    position: relative;
    display: flex;
    align-items: center;
}
.category .wpcf7-list-item-label{display: block;
    display: inline-block;
    padding: 0.3em 0 0.3em 3px;
    @include min-screen($breakpoint-pc){
    padding: 0.1em 25px 0.1em 3px;
    }
}
.category .wpcf7-list-item-label:before,
.category .wpcf7-list-item-label:after {
@include vertical-align; 
  position: absolute;
  content:"";
  border-radius: 50%;
}
.category .wpcf7-list-item-label:before {
  left: 0;
  width:16px;
  height:16px;
  border: 1px solid #999;
  background: #fff;   
}
.category .wpcf7-list-item-label:after {  
  width: 10px;
  height: 10px;
  background: $main-color;
  left:3px;
  opacity: 0;
}
input[type="radio"]:checked+.wpcf7-list-item-label:after,
.categorylabel.wpcf7-list-item-label:after{
  opacity: 1;
}
input[type="radio"]{
    margin-right: 5px;
    visibility: hidden;
}


//テキストエリアの基本設定
%txt-default {
    width: 100%;
    border: solid 1px $txt-gray;
    font-size: 1.6rem;
    padding:8px;
    margin:0;
    background: #fff;
    height: 45px;
    @include min-screen($breakpoint-pc) {
    margin: 0;
    height: 50px;
    padding: 10px;    
    width: 300px;
    }
}
.contact-form input[type="text"],
.contact-form input[type="tel"],
.contact-form input[type="address"],
.contact-form input[type="email"],
.contact-form select,
.wpcf7-form input[type="text"],
.wpcf7-form input[type="tel"],
.wpcf7-form input[type="address"],
.wpcf7-form input[type="email"],
.wpcf7-form select
{
@extend %txt-default;
}

.contact-form input[name="university"],
.contact-form input[name="mail"],
.contact-form input[name="address"],
.wpcf7-form input[name="university"],
.wpcf7-form input[name="mail"],
.wpcf7-form input[name="address"]{
    width: 100%;
    @include min-screen($breakpoint-pc) {
    width: 600px;
    }
}



.contact-form textarea,
.wpcf7-form textarea{
@extend %txt-default;    
}

.contact-form textarea[name="booking_title"],
.wpcf7-form textarea[name="booking_title"],
{
border: none;    
width: 100%;    
min-height: 50px;
height: auto;    
resize: none;
padding: 0;
border-radius: 0;    
}

.contact-form textarea[name="free-txt"],
.wpcf7-form textarea[name="free-txt"],
{
    height:200px;
    width: 100%;
    //margin: 10px 0 0;
    @include min-screen($breakpoint-pc) {
    width: 600px;
    }    
}

//プレースホルダー設定
:placeholder-shown {
    color: #969696;
}
/* Google Chrome, Safari, Opera 15+, Android, iOS */
::-webkit-input-placeholder {
    color: #969696;
}
/* Firefox 18- */
:-moz-placeholder {
    color: #969696; opacity: 1;
}
/* Firefox 19+ */
::-moz-placeholder {
    color: #969696; opacity: 1;   
}
/* IE 10+ */
:-ms-input-placeholder {
    color: #969696;font-size: 1.4rem;
}

//オートコンプリートの色をoff
//input:-webkit-autofill {
//    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
//}

//ご希望日のプレースホルダー設定
.contact-form input[name="booking_time"],
.wpcf7-form input[name="booking_time"]{
//プレースホルダー設定
&:placeholder-shown {
    color: #222;
}
/* Google Chrome, Safari, Opera 15+, Android, iOS */
&::-webkit-input-placeholder {
    color: #222;
}
/* Firefox 18- */
&:-moz-placeholder {
    color: #222; opacity: 1;
}
/* Firefox 19+ */
&::-moz-placeholder {
    color: #222; opacity: 1;   
}
/* IE 10+ */
&:-ms-input-placeholder {
    color: #222;font-size: 1.4rem;
}    
}

//エラーメッセージ
.error-message{flex-basis:720px;}
.error-message label,
.error-message span,
//span .wpcf7-not-valid-tip
{
    margin-top: -3px;
    margin-bottom:10px;
    color: red;
    padding: 0 5px;
    background: yellow;
    display:table;
    @include min-screen($breakpoint-pc) {
    margin-top: 5px;    
    padding: 2px 10px;
    }
}

//ContactForm7 デフォルトのエラーを非表示
span.wpcf7-not-valid-tip,
.wpcf7-response-output {
	display: none !important;
}

/*テーブル要素*/
.form-tbl {
    margin-top: 20px;
    border: 1px solid $table-border;
    & .tr{     
    }    
    & .th {
        padding:12px 10px;
        font-weight:500;
        background: $bg-color;
    }
    & .td {
        padding:16px 10px;
        
    }    
    & .must,
    & .optional{
        position: relative;
        padding-left:50px;
        &:before{
        text-align: center;
        font-size: 1.2rem;    
        position: absolute;    
        content: "必須";
        background:red;
        color: #fff;
        font-weight: 400;
        left:10px;
        top:calc(50% - 9px);
        //transform: translateY(-50%);
        display: block;
        height: 18px;
        width: 35px;
        line-height: 18px;    
    }
        }
    & .optional{
        &:before{
        content: "任意";    
        background:#c5c5c5;    
        }        
    }
    @include min-screen($breakpoint-pc){       
     margin: 30px 0 0;    
     display: block;
     border-right: none;
     border-left: none;    
        & .tr {
            display: flex;
            align-items:stretch;
            border-bottom: 1px solid $table-border;
        }
        & .th {
            padding: 20px;
            padding-left: 55px;
            width:260px;
            display: flex;
            align-items: center;
        }
        & .td{
            width:700px;
            padding: 20px 30px;
            
        }
        & .must,
        & .optional{
            padding-left: 75px;
            &:before{
            width: 45px;
            font-size: 1.2rem;
            height: 20px;
            line-height: 20px;
            top:calc(50% - 10px);
            left: 20px;
            }    
        }
        & .tr:last-of-type{
            border-bottom: none;
        }        
        }  
}

.form-note,
.postalcode-note{
display: block;    
font-size: 1.3rem;
margin-top: 10px;
color: $em-color;    
    @include min-screen($breakpoint-pc){
    font-size: 1.4rem;    
    }    
}

.form-contact{
    margin-bottom: 40px;
    @include min-screen($breakpoint-pc){
    margin-bottom: 90px;    
    }
}
.form-conf{
    text-align: center;
    margin:30px 0;
    line-height: 1.8;
    @include min-screen($breakpoint-pc) {    
    margin:40px 0;        
    }    
}

.h-privacy-policy{
    text-align: center;
    font-size: 1.5rem;
    @include min-screen($breakpoint-pc) {
    font-size: 1.8rem;    
    }
}
.contact-agreement {
    border: 1px solid $main-color;
    margin-bottom: 20px;
    padding: 0 16px 20px;
    overflow: hidden;

    & h4 {
        background: $main-color;
        color: #fff;
        text-align: center;
        padding: 10px;
        margin: 0 -16px;
        font-size: 1.5rem;
    }

    & p {

        padding-top: 20px;
        margin-bottom: 10px;
    }

    & label {
        // display: block;
        // margin-top: 10px;
    }

    & input[type="text"] {
        margin: 10px 10px 0;
        width: calc(100% - 20px);
        display: block;
    }

    & input[type="checkbox"] {
        margin: 0 5px 0 10px;
    }
    @include max-screen($breakpoint-pc) {    
    & span{
        margin:-5px 0 3px;
    }
 }
    @include min-screen($breakpoint-pc) {
        width: 800px;
        margin: 0 auto 30px;
        padding: 0 40px 40px;

        & h4 {
            font-size: 1.6rem;
            padding:10px 16px;
            margin: 0 -40px;
        }

        & p {
            padding-top: 30px;
        }

        & input[type="text"] {
            width: 300px;
            margin-left: 20px;
        }

        & input[type="checkbox"] {
            margin-left: 20px;
        }
    }
}
.form-calendar{
display: inline-block;    
width: 48px;  
margin-left: 5px;   
    @include min-screen($breakpoint-pc) {
        margin-left: 10px; 
        width: 53px;
    }    
}

.form-event-name{
    font-weight: 700;
    margin-bottom: -10px;
    @include min-screen($breakpoint-pc) {
    font-size: 2rem;
    padding-top: 5px;    
    }    
}
.event-name{
//    display: -webkit-flex;
//    display: -moz-flex;
//    display: -ms-flex;
//    display: -o-flex;
//    display: flex;
//    align-items: center;
    & .th{
        padding-top: 16px;
        padding-bottom: 5px;
    }
    @include min-screen($breakpoint-pc) {    
    & .th{
        padding-top: 45px;
        padding-bottom: 40px;
    }
}
}


//確認画面

.form-tbl--conf{

        & .tr{
        &:nth-last-of-type(1),
        &:nth-last-of-type(2),
        &:nth-last-of-type(3){
            display: none;
        }
    }
    @include min-screen($breakpoint-pc) {
        border-bottom: none;    
    }    
}


#check_form_block{
    & .th{
        padding-top: 10px;
        padding-bottom: 5px;
        &.must,&.optional{
            &:before{
                top: 10px;
            }
    }
}
        & .td{
            padding-top: 0;
            padding-bottom: 16px;
        }    
    @include min-screen($breakpoint-pc){    
    & .th,& .td{
        padding-top: 20px;
        padding-bottom: 20px;
        &.must,&.optional{
            &:before{
                top: 20px;
            }
    }
}
    }}

.is_check--txt{
    text-align: center;
    font-weight: 700;
    font-size: 1.6rem;
    @include min-screen($breakpoint-pc) {
    font-size: 2rem;    
    }    
}

//確認ボタン
.form-btn--check,
button.form-btn--check {
    @include btn($bg:$submit-color,$hover:lighten($submit-color,8%));
    color:#fff;
    width: 100%;
    max-width: 100%;
    font-size: 2.5rem;
    border-radius: 60px;
    @include max-screen($breakpoint-iphone5) {
    font-size: 2.2rem;
    padding-right: 13px;    
    }    
    padding: 0.4em 5px;
    @include min-screen($breakpoint-pc) {
        width:100%;
        font-size:2.4rem;
        padding-top: 20px;     
        padding-bottom:20px;
        &:focus {
            outline: 0;
        }
    }
}
//戻る
button.form-btn--return{
@include btn($bg:#ccc,$hover:#dadada);
}
//送信
button.form-btn--submit{
@include btn($bg:$submit-color,$hover:lighten($submit-color,8%));
}
button.form-btn--return,
button.form-btn--submit {
    width: 100%;
    max-width: 100%;
    border-radius: 60px;
    @include min-screen($breakpoint-pc) {
        position: relative;
        width:250px;
        min-width: 250px;
        font-size: 2.4rem;
        padding-top: 20px;     
        padding-bottom:20px;        
    }
}


.l-btn--submit{
position: relative;
justify-content: center;
display: flex;
width: 100%;
margin:30px auto 45px;
padding: 0; 
color: #fff;    
    &:before{
        position: absolute;
        top:50%;
        right:10px;
        transform: translateY(-50%);
        z-index: 3;
        font-size: 2.5rem;
    }
    @include min-screen($breakpoint-pc) {
        width: 400px;
        margin-top:50px;
        margin-bottom: 70px;
        transition:transform $ease-scale $speed;
        &:before{
        font-size: 3.6rem;
        right: 20px;
        }                
    }    
}
.l-btn_1of2--submit{
    margin-top: 30px;
    margin-bottom: 45px;
    @include min-screen($breakpoint-pc) {     
    width: 100%;
    margin-top:50px;
    margin-bottom: 60px;    
    display: flex;
    justify-content: center; 
}
}
.wrap-btn-submit,
.wrap-btn-return {
    position: relative;
    width: 75vmin;
    @include max-screen($breakpoint-iphone5) {
        width: 100%;
    }
    margin:0 auto;
    &:before {
        position: absolute;
        z-index: 2;
        color: #fff;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        font-size: 1.8rem;
    }
    @include min-screen($breakpoint-pc) {
        transition: transform $ease-scale $speed, background $ease-scale $speed;
        &:before {
            font-size: 2rem;
        }
        width: 250px;
        margin: 0 8px;
    }
}
.wrap-btn-return{
    margin-bottom: 10px;
    &:before{
        right: auto;
        left:10px;
    }
    @include min-screen($breakpoint-pc) {
    margin-bottom: 0;
        &:before{
            left: 15px;
            transition:left $ease-scale $speed
        }
       
    }    
}
.wrap-btn-submit {
    @include min-screen($breakpoint-pc) {
        &:before{
            right: 15px;
            transition:right $ease-scale $speed
        }             
    }
}


//コンタクトフォームのローダ・メッセージ非表示 
.wpcf7-validation-errors,
.wpcf7 .ajax-loader,
.wpcf7-mail-sent-ok {
    display: none !important;
}

.mustlabel{
        text-align: center;
        font-size: 1.2rem;    
        background:red;
        color: #fff;
        font-weight: 400;
        display: inline-block;
        height: 18px;
        width: 35px;
        line-height: 18px; 
         margin-right: 3px;
    @include min-screen($breakpoint-pc) {
            width: 45px;
            font-size: 1.2rem;
            height: 20px;
            line-height: 20px;
        margin-right: 5px;
    }    
}




