@charset "UTF-8";
html {
    font-size: 62.5%;
    //overflow-y:auto;
    //overflow-x: hidden;
}
//header,footer{
//    max-width: 100%;
//    overflow-x: hidden;
//    //pointer-events: none;
//}
//.body-wrap{
//    max-width: 100%;
//    overflow-x: hidden;
//    //overflow-y:visible;
//}

//フォントの設定
@mixin font{
    font-family: "Helvetica Neue",
    Arial,
    Meiryo,    
    "Hiragino Kaku Gothic ProN",
    "Hiragino Sans",    
    sans-serif;
}
//フォントの設定
@mixin noto{
    font-family: 'Noto Sans JP', sans-serif;
}

body {
    overflow:visible;
    overflow-x:hidden;
    @include noto;
    font-weight: 400;
    position: relative;
    font-size: 12px;    
    font-size: 1.2rem;
    color: $txt-color;
    @include min-screen($breakpoint-pc) {
    overflow-x: hidden;    
    font-size: 14px;    
    font-size: 1.4rem;      
    }
}

h1,h2,h3,h4,h5{
    font-weight: 500;
}


a {
    text-decoration: none;
    color: inherit;
    outline:none;
    font-weight: 500;
    &:hover
    {outline:none;}
}
*:focus,
*:active{
outline:none;
outline-offset: 0;    
}

p {
    //line-height: 2;
}
p[class$="-txt"],
p[class*="-txt"]{
    line-height: 1.8;
}
  .google-link,
  .txt-link,
  .txt-tellink a {
      color: $link-color;      
      transition: color $speed;

      @include min-screen($breakpoint-pc) {
          &:hover {
              text-decoration: underline;
              color: $link-hover;
          }
      }
  }

img{
max-width: 100%;
}

.SvgIcon {
  position: absolute;
  width: 0;
  height: 0; 
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: "font";
  src:url("../fonts/font.eot");
  src:url("../fonts/font.eot?#iefix") format("embedded-opentype"),
    url("../fonts/font.woff") format("woff"),
    url("../fonts/font.ttf") format("truetype"),
    url("../fonts/font.svg#untitled-font-1") format("svg");
  font-weight: normal;
  font-style: normal;
}
[data-icon]:before,
[data-icon]:after{
  font-family: "font" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon"]:before,
[class*=" icon"]:before, 
[class^="icon"]:after,
[class*=" icon"]:after {
  font-family: "font" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-up:before {
  content: "\61";
}
.icon-arrow-r:before {
  content: "\62";
}
.icon-arrow-l:before {
  content: "\63";
}
.icon-arrow-down:before {
  content: "\64";
}
.icon-mail:before {
  content: "\6f";
  font-size: 1.15em;    
}
.icon-tel:before {
  content: "\6d";
    font-size: 0.9em;
    margin-right: 0.25em;    
}
.icon-blank:after {
  content: "\6e";
  font-size: 0.8em;
  margin-left: 0.3em;
  margin-right: 0.3em;    
}
.icon-icon-down:before {
  content: "\70";
}
.icon-icon-l:before {
  content: "\71";
}
.icon-icon-r:before {
  content: "\72";
}
.icon-icon-up:before {
  content: "\73";
}
.icon-cart:before {
  content: "\66";
}
.icon-person:before {
  content: "\67";
}